<div class="container" *ngIf="queryParams.inputcaption1">
  <div class="row">

    <div class="col-md-6 offset-md-3 mt-5">
      <div class="card" *ngIf="confRegObj.id">
        <div class="card-body">
          <h5 class="card-title">Conference Information</h5>
          <hr />
          <table class="table">
            <tbody>
              <tr>
                <th>ID</th>
                <td>{{ confRegObj.id }}</td>
              </tr>
              <tr>
                <th>Conference Code</th>
                <td>{{ confRegObj.conferencecode }}</td>
              </tr>
    
             
              
              <tr>
                <th>Name</th>
                <td>{{ confRegObj.nameinreceipt }}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{{ confRegObj.address1 }}</td>
              </tr>
              <tr>
                <th>Phone</th>
                <td>{{ confRegObj.phone }}</td>
              </tr>
             
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="col-md-6 offset-md-3 mt-5">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            Payment successful! Your registration for the Symposium is complete.
          </h5>
          <p class="card-text">
            Reg No: {{ queryParams.regno }}
            <br />
            Payment status:
            <span class="text-info">{{
              queryParams.inputcaption1 == "Y" ? "SUCCESS" : "UNKNOWN"
            }}</span>
            <br />
            Transaction ID: {{ queryParams.responseTransid }}
          </p>
          <!-- Add the links here -->
          <p>
            <a href="/">Go Home</a> |
            <a
              href="javascript:void(0);"
              class="btn btn-primary"
              (click)="printPage()"
              >Print</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="!queryParams.inputcaption1">
  <div class="row">
    <div class="col-md-6 offset-md-3 mt-5">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Something went wrong..</h5>
        </div>
      </div>
    </div>
  </div>
</div>
