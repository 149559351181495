<!-- START FETURES -->
<section class="section bg-honey"  id="features">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-12">
                <i class="ti-bookmark-alt title-icon text-muted"></i>
                <h3 class="title text-primary"><span class="fw-bold">Ranipet Campus</span></h3>
                <p class="text-muted mt-3 title-primary mx-auto">Level 1 Trauma Care Facility at Ranipet Campuss</p>
            </div>
        </div>
        <div class="row mt-5 vertical-content">
            <div class="col-lg-6 mt-2">
                <div class="card">
                    <div class="card-body">
                        <img src="assets/images/cmc/p1.png" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>
            </div>
            <div class="col-lg-5 offset-lg-1 mt-2">
                <div class="features-desc">
                    <!-- <h2>This is Improve Your Marketing <br> business</h2> -->
                    <p class="text-muted mt-3">
                        The Level 1 Trauma Care Facility with dedicated ambulance entry has started functioning at the
                        CMC Vellore Ranipet Campus.The facility is located on the ground floor of Block D, co-located
                        with  the Cardiac Care Unit. It includes an Emergency Bay, 6 state-of-the-art trauma operation
                        theatres, a radiology suite and 112 ward & ICU beds.

                    </p>
                    <!-- <a href="#" class="btn btn-primary btn-round mt-3">Read more<i
                            class="mdi mdi-chevron-right"></i></a> -->
                </div>
            </div>
        </div>


        <div class="row mt-5 pt-5 vertical-content">
            <div class="col-lg-5 mt-2">
                <div class="features-desc">
                    <!-- <h2>This Is Increase Your Marketing Performance</h2> -->
                    <p class="text-muted mt-3">Trauma is an unforeseen incident which is life or limb threatening. The
                        immediate commencement of trauma care is important for life and limb saving. In this Context the
                        Department of trauma surgery commits itself to offer quality services towards Primary assessment
                        and surgical interventions for the Injured Victim in a comprehensive manner. The subsequent
                        review and follow-up as Outpatient services.</p>
                    <!-- <a href="#" class="btn btn-primary btn-round mt-3">Read more<i
                            class="mdi mdi-chevron-right"></i></a> -->
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 mt-2">

                <div class="card">
                    <div class="card-body">
                        <img src="assets/images/cmc/ct.jpg" alt="" class="img-fluid mx-auto d-block">
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<!-- END FEATURES -->