<!-- START SERVICES -->
<section class="section" id="services">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-12">
                <i class="ti-ruler-pencil title-icon text-muted"></i>
                <h3 class="title"> <span class="fw-bold">about us</span></h3>
                <p class="text-muted mt-3  mx-auto">Christian Medical College Vellore was started as a
                    one-bedded clinic-cum-dispensary in 1900 by Dr. Ida Sophia Scudder, the daughter of
                    second-generation medical missionaries. Today, this healthcare organization of international repute
                    includes a network of primary, secondary, tertiary and quaternary care hospitals, with around 3844
                    beds spread across six campuses in and around Vellore, and in the neighboring state of Andhra
                    Pradesh. The main hospital is situated in the 19-acre Town Campus (earlier referred to as the
                    Thottappalayam Campus) in the heart of Vellore town. The hospital complex is the site for clinical
                    training for all the students. The vast majority of the clinical departments are located on the
                    hospital campus.  From April 2016 to March 2017, the hospital, which has close to 2500 beds,
                    received more than 21 lakh outpatients, more than a lakh inpatients and recorded more than 14, 500
                    births.</p>
            </div>
        </div>
        <!-- <div class="row mt-5">
            <div class="col-md-4" *ngFor="let item of servicesData">
                <div class="services-box">
                    <div class="services-icon">
                        <i :class="{{item.icon}} text-primary"></i>
                    </div>
                    <div class="mt-3">
                        <h5 class="services-title fw-bold mb-3">{{item.title}}</h5>
                        <p class="services-subtitle text-muted"> Ut enim ad minim veniam, quis nostrud exercitation
                            ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- end col -->
        <!-- end row -->
    </div>
</section>
<!-- END SERVICES -->