<!-- START TEAM -->
<section class="section" id="faq">
  <div class="container">
    <div class="row justify-content-center text-center">
      <div class="col-lg-12">
        <i class="ti-comments title-icon text-muted"></i>
        <h3 class="title"><span class="fw-bold">Our</span> focus is:</h3>
        <!-- <p class="text-muted mt-3 title-subtitle mx-auto">It is a long established fact that a reader will
                    be of a page when established fact looking at its layout.</p> -->
      </div>
    </div>
    <div class="row text-center mt-2 ">
      <div class="m-1">
        <h5 class="mb-0 f-18 font-weight-600">
          To save lives and improve quality of life for survivors
        </h5>
      </div>

      <div class="m-1">
        <h5 class="mb-0 f-18 font-weight-600">
          To minimise disability and other long-term consequences of traumatic
          injuries
        </h5>
      </div>

      <div class="m-1">
        <h5 class="mb-0 f-18 font-weight-600">
          To reduce the economic and social impact of injuries
        </h5>
      </div>

      <div class="m-1">
        <h5 class="mb-0 f-18 font-weight-600">
          To advance the trauma care field, enhance outcomes and improve the
          quality of care at all levels national and international.
        </h5>
      </div>
    </div>
  </div>
</section>
<!-- END TEAM -->

<!-- START CTA -->
<!-- <section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center">
                    <h3 class="mb-4">You have another questions or need help?</h3>
                    <div class="">
                        <a href="#" class="btn btn-round btn-primary">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- END TEAM -->

<!-- <ng-template #content>
    <p class="text-muted">You want customer to your store. Easily publish your coupans and
        when a user has manage to catch one of your coupens, the coupens wil be deducted
        from your coupens account at Clooger.</p>
</ng-template> -->
