<div class="container mt-3" *ngIf="!showTable">
  <!-- Secret key input form -->
  <div class="form-group">
    <label for="secretKey">Enter Secret Key:</label>
    <input
      class="form-control"
      type="password"
      id="secretKey"
      [(ngModel)]="secretKey"
    />
    <button (click)="checkSecretKey()">Submit</button>
  </div>
</div>

<!-- Table - Display only if showTable is true -->
<div class="row p-2" *ngIf="showTable">
  <button class="btn btn-sm btn-success float-end" (click)="exportToExcel()">
    Export to Excel
  </button>

  <div class="table-responsive">
    <table class="table" id="myTable">
      <thead class="thead-dark">
        <tr>
          <th>ID</th>
          <th>Registration Number</th>
          <th>Transaction ID</th>
          <th>Name in Receipt</th>
          <!-- <th>Conference Code</th> -->
          <th>Bank Name</th>
          <th>Phone</th>

          <th>Designation</th>
  
          <th>Place of work</th>
  
          <th>Name of the hospital, college</th>
  
          <th>ISTAC member</th>
          <th>CMC Mission</th>
  
          <th>External or CMC staff</th>
  
          <th>registration type</th>
  
          <th>submit case</th>
         

          <th>Email</th>
         
          <th>Payment Amount</th>
          <th>Payment Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let payment of payments">
          <tr>
            <td>{{ payment.id  || "NA"}}</td>
            <td>{{ payment.regno  || "NA"}}</td>
            <td>{{ payment.responseTransid || payment.Transid  || "NA"}}</td>
            <td>{{ payment.nameinreceipt  || "NA"}}</td>
            <!-- <td>{{ payment.conferencecode  || "NA"}}</td> -->
            <td>{{ payment.bankname  || "NA"}}</td>
            <td>{{ payment.phone  || "NA"}}</td>

            <td>{{  payment.inputvalue1  || "NA"}}</td>
            
            <td>{{  payment.inputcaption2  || "NA"}}</td>
            
            <td>
              <span *ngIf=" payment.inputcaption3">{{  payment.inputcaption3  }}</span
              ><span *ngIf=" payment.inputvalue2">{{  payment.inputvalue2  }}</span>
            </td>
            
            <td>{{ payment.inputvalue3 || "NA"}}</td>
            <td>{{ payment.city || "NA"}}</td>
            
            <td>{{payment.inputvalue5 == 'No Error' ?	"NA" :  payment.inputvalue5}}</td>


            
            <td>{{payment.inputvalue4 || "NA"}}</td>
            
            <td>{{ payment.inputcaption5 || "NA"}}</td>
            

            <td>{{ payment.email  || "NA"}}</td>
            <td>{{ payment.paymentamount  || "NA"}}</td>
            <td>
              <span
                [ngClass]="{
                  success: payment.inputcaption1 === 'Y',
                  failed: payment.inputcaption1 === 'F',
                  unknown:
                    payment.inputcaption1 !== 'Y' &&
                    payment.inputcaption1 !== 'F'
                }"
              >
                {{
                  payment.inputcaption1 === "Y"
                    ? "Success"
                    : payment.inputcaption1 === "F"
                    ? "Failed"
                    : "Unknown"
                }}
              </span>
            </td>
            <td>
              <!-- <button class="mx-2" (click)="delete(payment.id)">
                <i class="ti-trash"></i>
              </button> -->

              <button (click)="viewDetail(payment)">
                <i class="ti-eye"></i>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
