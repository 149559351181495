<!-- STRAT TESTIMONIAL -->
<section class="section bg-client" id="testi">
    <div class="container">
        <div class="row justify-content-center mt-5 mb-5">
            <div class="col-lg-12">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="text-center w-75 mx-auto">
                            <div class="testi-icon text-white">
                                <i class="ti-quote-left"></i>
                            </div>
                            <div class="mt-3">
                                <p class="user-review text-center">Risus cubilia etiam parturient augue nostra
                                    sodales sit aptent venenatis magna sapien
                                    ante hendrerit ullamcorper tincidunt urna eget Ante feugiat.</p>
                                <div class="">
                                    <img src="assets/images/testi/testi-1.jpg" alt=""
                                        class="rounded-circle testi-user mx-auto d-block">
                                </div>
                                <p class="testi-user-name text-center text-white mb-0 mt-4">- John Litts,
                                    Globing</p>
                                <p class="text-muted">
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                </p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="text-center w-75 mx-auto">
                            <div class="testi-icon text-white">
                                <i class="ti-quote-left"></i>
                            </div>
                            <div class="mt-3">
                                <p class="user-review text-center">Risus cubilia etiam parturient augue nostra
                                    sodales sit aptent venenatis ullamcorper tincidunt urna, eget magna sapien
                                    ante hendrerit Ante feugiat.</p>
                                <div class="">
                                    <img src="assets/images/testi/testi-2.jpg" alt=""
                                        class="rounded-circle testi-user mx-auto d-block">
                                </div>
                                <p class="testi-user-name text-center text-white mb-0 mt-4">- John Litts,
                                    Globing</p>
                                <p class="text-muted">
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star"></span>
                                </p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="text-center w-75 mx-auto">
                            <div class="testi-icon text-white">
                                <i class="ti-quote-left"></i>
                            </div>
                            <div class="mt-3">
                                <p class="user-review text-center">Risus cubilia etiam parturient augue nostra
                                    sodales sit aptent venenatis magna ullamcorper tincidunt urna, eget sapien
                                    ante hendrerit Ante feugiat.</p>
                                <div class="">
                                    <img src="assets/images/testi/testi-3.jpg" alt=""
                                        class="rounded-circle testi-user mx-auto d-block">
                                </div>
                                <p class="testi-user-name text-center text-white mb-0 mt-4">- John Litts,
                                    Globing</p>
                                <p class="text-muted">
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                </p>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- END TESTIMONIAL -->